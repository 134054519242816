.card-controls.pg-queries-card-controls {
  display: flex;
  flex-direction: row-reverse;
  .pg-queries-switch {
    margin-right: 32px;
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    .switch {
      padding-left: 16px; } } }
