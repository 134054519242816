.loadable-collection {
  min-height: 155px;
  background-color: white;

  .centered-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .spinner-layer {
      border-color: $secondary-color; }
    .no-data {
      i {
        color: #e0e0e0;
        margin: auto;
        font-size: 5rem; } } } }

.collection {
  .collection-item {
    i.collection-item-icon {
      font-size: 16px;
      position: relative;
      top: 3px; }
    svg {
      height: 16px;
      width: 16px;
      position: relative;
      top: 3px; }
    span.badge {
      color: white; }
    a.secondary-content.btn-flat {
      line-height: 1.5rem;
      height: 1.5rem;
      .btn__text {
        position: relative;
        bottom: 3px; } } } }
