.sc-button-group {
  font-size: 0px;
  box-shadow: rgba(0,0,0,.5) 0 0 5px;
  border-radius: 2px;
  float: right;
  margin-bottom: 20px;
  >div:last-child {
    a {
      border-radius: 0 2px 2px 0px; } }
  >div:first-child {
    a {
      border-radius: 2px 0 0 2px; } }
  >div {
    display: inline-block;
    font-size: initial; }
  .btn {
    box-shadow: none;
    border-radius: 0px; } }


