main {
  position: relative;
  z-index: 0; }

h3 {
  margin-top: 2rem; }

footer {
  a {
    display: inline-block;
    width: 128px; } }

@media #{$large-and-up} {
  header {
    ~ .side-nav-open, .side-nav-open {
      padding-left: $sidenav-width; } } }

@media #{$medium-and-up} {
  header ~ main > .ember-view {
    padding: 24px 2%;
    min-height: calc(100vh - #{$navbar-height} - 64px - 48px);
    > .row > .col {
      &:nth-child(2) {
        padding-left: 0; } } } }

@media #{$small-and-down} {
  header ~ main {
    padding-top: $navbar-height-mobile + 48px;
    > .ember-view {
      min-height: calc(100vh - #{$navbar-height-mobile} - 64px - 48px);
      padding: 8px; }

    .container {
      width: 98%; } } }
