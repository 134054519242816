// Global
body {
  background-color: $body-bg;
  color: $text-color; }
a {
  outline: none; }

// Alert

.bg-danger {
  background-color: #f2dede; }
a.bg-danger:hover, a.bg-danger:focus {
  background-color: #e4b9b9; }
p.bg-danger {
  padding: 15px; }

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px; }
.alert-danger {
  color: color('red', 'base');
  background-color: color('red', 'lighten-5');
  border-color: color('red', 'lighten-4');
  hr {
    border-top-color: color('red', 'lighten-4'); }
  .alert-link {
    color: color('red', 'darken-2'); } }
.alert-warning {
  color: color('yellow', 'darken-2');
  background-color: color('yellow', 'lighten-5');
  border-color: color('yellow', 'lighten-1');
  hr {
    border-top-color: color('yellow', 'lighten-1'); }
  .alert-link {
    color: color('yellow', 'darken-4'); } }

// Card
main .card {
  overflow: visible;
  .card-action {
    a {
      transition: all .3s ease;
      margin-right: 0; }
    .row {
      margin-bottom: 0; } }
  .card-title {
    font-weight: 600;
    line-height: 1 !important;
    + p {
      margin-top: 16px; } }
  h2 {
    font-size: 24px; }
  h3 {
    font-size: 20px; }
  h4 {
    font-size: 18px; }
  .card-content {
    .row:last-child {
      margin-bottom: 0; } } }

// Modal
.ember-modal-dialog {
  z-index: 1005; }
.ember-modal-overlay {
  z-index: 1000; }
.modal {
  @include custom-scrollbar;
  h2 {
    font-size: 24px; }
  h3 {
    font-size: 20px; } }
.modal-footer {
  padding-left: 8px !important;
  padding-right: 8px !important; }
.modal-content {
  .row:last-child {
    margin-bottom: 0; } }

// Toast
#toast-container {
  max-width: 90%;
  bottom: 3%; }

.toast {
  height: auto;
  font-weight: normal;
  line-height: 24px;
  padding: 14px 25px; }

@media #{$small-and-down} {
  #toast-container {
    bottom: 0; }
  .toast {
    &:not(:first-child) {
      margin-top: 0; } } }

@media #{$large-and-up} {
  #toast-container {
    top: initial;
    right: initial;
    bottom: 3%;
    left: 3%;
    max-width: 80%; }
  .toast {
    float: left; } }

// Button

.btn, .btn-large, .btn-flat {
  padding: 0 1rem;
  overflow: hidden;
  position: relative; }

.btn-color {
  &.btn-flat {
    color: $secondary-color;
    background-color: rgba(0,0,0,0) !important; } }

.btn-loading {
  .progress {
    position: absolute;
    margin: 0;
    left: 0;
    bottom: 0;
    height: 2px; } }
.btn__text {
  display: inline-block;
  height: 36px; }

// Table layout

.table {
  display: table;
  margin: 0;
  width: 100%;
  .row {
    display: table-row;
    .table-cell {
      border-top: 1px solid #ddd; } }
  .table-header {
    display: table-header-group;
    font-weight: bold;
    .table-cell {
      border-bottom: 2px solid #ddd; } }
  .table-cell {
    display: table-cell;
    padding: 0 4px;
    a {
      margin: 4px; } } }

.bordered tbody tr:last-child {
    border-bottom: 0; }

.table-like {
  margin: 0;
  .table-like-header {
    padding: 0 0 8px;
    border-bottom: 2px solid #ddd;
    font-weight: bold; }
  .row {
    margin-left: 0;
    margin-right: 0; }
  > .row {
    border-top: 1px solid #ddd;
    padding: 8px 0; } }

hr {
  border: none;
  border-top: #ddd 1px solid;
  margin: 16px 0; }

// Form

.input-field {
  i ~ small {
    margin-left: 45px; }

  input, textarea {
    margin-bottom: 0 !important;
    &.valid {
      border-bottom: 1px solid $input-border-color !important;
      box-shadow: none !important; }
    &:focus {
      border-bottom: 1px solid $input-focus-color !important;
      box-shadow: 0 1px 0 0 $input-focus-color !important; } }

  label {
    width: calc(100% - 0.75rem - 0.75rem);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    + small {
      color: $input-border-color; } }

  .prefix ~ label {
    width: calc(100% - 0.75rem - 0.75rem - 45px); }

  .input-suffix {
    float: right;
    position: absolute;
    top: 12px;
    right: 12px;
    color: #9e9e9e; } }

.select-dropdown {
  @include custom-scrollbar;
  &.active {
    min-width: 64px;
    max-height: 256px; } }
// Helpers
.flex-none {
  flex: none !important; }

.flex-inline {
  display: flex;
  > * {
    flex: 1; } }

.nowrap {
  white-space: nowrap; }

.img-responsive {
  max-width: 100%; }

.hidden {
  display: none;
  visibility: hidden; }

.text-muted {
  color: #777777 !important; }

.tabs-flex {
  display: flex;
  justify-content: space-around; }
.tab-flex {
  flex: 1; }

.strike {
  text-decoration: line-through; }

// .pro-tip
//   margin-top: 16px

// Code blocks

.code,
.timeline,
.variables,
#endRenameAppModal,
.containers,
.domain-management {
  code {
    padding: 2px 4px;
    color: #c7254e;
    background-color: #f9f2f4;
    border-radius: 4px;
    word-break: break-word; } }
code, kbd, pre, samp, .variables textarea {
  @include custom-scrollbar;
  font-family: $font-family-monospace;
  font-size: 90%; }

.action-buttons {
  display: inline-flex; }

.nowrap {
  white-space: nowrap; }

.card-controls {
  text-align: right;
  margin-top: -45px; }

blockquote.error {
    border-left: 5px solid #f41403; }

blockquote.warning {
    border-left: 5px solid #dbab09; }


// Tooltips
.tooltipped {
  cursor: pointer; }

// Switches
.sc-md-switch {
  display: flex;
  .switch-label {
    flex-grow: 1; }
  .preloader-wrapper {
    height: 20px;
    width: 20px;
    margin-right: 5px; } }

// Misc
.mt-0 {
  margin-top: 0 !important; }
.doc-link {
  vertical-align: text-top;
  font-size: 20px; }

// power-select

.ember-power-select-multiple-options {
  input {
    border-bottom: none;
    margin: 0;
    height: auto;
    &:focus {
      border-bottom: none !important;
      box-shadow: none !important; } } }

// Loader
.centered-container {
	display: flex;
	justify-content: center;
	align-items: center; }

