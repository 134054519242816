.c3-ygrid-line {
  line {
    stroke: #757575;
    stroke-dasharray: 5,5; }
  text {
    fill: #a9a9a9 !important; } }

.c3-ygrids {
  line {
    stroke: #ddd !important;
    stroke-dasharray: 0; } }

.c3-tooltip {
  th {
    border-radius: 0; } }

.c3 {
  text {
    fill: #aaa; }
  path {
    stroke: #aaa; }
  line {
    stroke: #aaa; } }

.c3-event-rects {
  fill: black;
  fill-opacity: 1; }

.c3-line {
  stroke-width: 1px; }

.c3-area {
  opacity: 0.2 !important; }

.c3-zoom-rect {
  fill: transparent;
  stroke: #aaa;
  opacity: 1 !important; }

.single-chart {
  height: 150px; }

.graph-container {
  margin-top: -25px;
  margin-bottom: 10px;
  h4 {
    margin-left: 10px;
    color: #555;
    font-size: 14px;
    line-height: 1; } }

.error-placeholder {
  margin-top: -150px;
  height: 125px !important; }

.graph-container-title {
  margin: 20px 0 0 0;
  line-height: 1;
  font-size: 24px;
  color: darkgray; }

.events-graph {
  margin-bottom: 10px;

  .metrics-container-placeholder {
    height: 100px; }

  .error-placeholder {
    margin-top: -100px;
    height: 75px !important; }

  .single-chart {
    height: 100px; }

  .c3-axis-y {
    .tick {
      line {
        opacity: 0 !important; } }
    path {
      opacity: 0; } } }

.graph-metrics-title {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 1.2rem; }

.metrics-container-placeholder {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h4 {
    color: #aaa; }
  h5 {
    margin-top: 10px;
    color: #bbb; } }

.container-action-buttons {
  margin-top: -30px;
  margin-bottom: 15px; }

.container-action-buttons {
  a {
    padding: 0 5px 0 5px;

    .waves-ripple {
      display:none !important {} }

    i {
      margin-top: 3px;
      line-height: 30px;
      height: 30px;
      width: 30px;
      text-align: center;
      border-radius: 15px;
      color: #7E7E7E; }
    i.left {
      margin-right: 0; } }

  a:hover {
    i {
      box-shadow: 0px 0px 3px; } } }


.metrics-divider {
  margin-left: -20px;
  margin-right: -20px; }

.single-chart-legend {
  margin-top: 5px;
  width: 55px;
  height: 65px;
  position: absolute;
  overflow: hidden;
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  font: 10px sans-serif;
  color: #aaa;

  .legend-item::before {
    content: "";
    border-radius: 5px;
    margin-right: 5px;
    display: inline-block;
    width: 10px;
    height: 10px; }

  #deploy::before {
    background-color: #085B9C; }

  #crash::before {
    background-color: #AA0000; }

  #restart::before {
    background-color: #089C3A; }

  #scale::before {
    background-color: #008F81; } }

.metrics-timezone {
  @media #{$medium-and-up} {
    float: right;
    width: 300px;
    margin-top: -25px;
    margin-right: 25px; } }

.metrics-time {
  float: right; }

