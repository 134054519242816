@keyframes looprotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.backup-status {
  i.material-icons {
    position: relative;
    margin-top: 6px; }
  .backup-status-done {
    color: green; }
  .backup-status-running {
    color: brown;
    animation: looprotate 2.0s infinite linear; }
  .backup-status-error {
    color: red; } }

.title-ctrl {
  display: flex;
  flex-flow: row-reverse;
  margin: 1.28rem 0 1.024rem 0; }

.flex-row {
  display: flex;
  align-items: baseline;
  .col {
    padding-left: 0.25rem;
    first-child: {
     padding-left: 0; }
    .btn {
      padding-left: 1rem; }
    &.grow {
      flex-grow: 1; }
    .dropdown-content li > a, .dropdown-content li > span {
      padding-left: 16px !important; } } }

.datetimepicker {
  display: flex;
  align-items: baseline;
  .ember-power-select-trigger {
    cursor: pointer; }
  .ember-power-select-status-icon {
    display: none; }
  first-child: {
    flex-grow: 1; }
  last-child: {
    flex-grow: 2; }
  .ember-power-select-trigger {
    border-bottom: none; } }

.datetimepicker.disabled {
  .ember-power-select-trigger {
    cursor: default; } }

.error-message {
  padding: .5rem;
  margin: .5rem 0; }

.disablable-form {
  transition: .5s;
  &.disabled {
    filter: grayscale(1);
    opacity: .2; } }

.pitr-status {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  .icon {
    min-width: 40px;
    width: 40px; }
  .pitr-status-running {
    color: brown;
    animation: looprotate 2.0s infinite linear; }
  .pitr-status-error {
    color: red; } }

