.db-advanced {
  .materialize-switch {
    .switch-label {
      font-weight: bold; } } }

.db-advanced-mongo {
  ul {
    padding-left: 16px;
    li {
      list-style-type: disc; } } }

.db-advanced-postgresql {
  div.extensions-list {
    margin-top: 12px;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    ul {
      margin: 0;
      padding-left: 40px;
      li {
        list-style-type: disc; } } } }

.db-advanced-elasticsearch {
  .feature {
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 10px;
    width: 85%;
    tt {
      font-family: Consolas, Menlo, 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Lucida Console';
      background: #f8f8f8;
      padding: 0 3px;
      font-size: 0.9em; } } }


@media #{$medium-and-down} {
  .db-advanced-postgresql {
    div.extensions-list {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2; } } }

@media #{$small-and-down} {
  .db-advanced-postgresql {
    div.extensions-list {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1; } } }
