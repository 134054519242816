$text-color:                 #212121;
$material-light-green-50:    #f1f8e9;
$material-light-green-100:   #dcedc8;
$font-family-monospace:      "Inconsolata", monospace;
$font-family-monospace-size: 16px;
$body-bg:                    #fafafa;

$primary-color-name:   "light-blue";
$secondary-color-name: "pink";// 'materialize-red'

$primary-color:   color($primary-color-name, "base") !default;
$secondary-color: color($secondary-color-name, "base") !default;

$card-link-color:       $secondary-color;
$card-link-color-light: $secondary-color;

$tabs-underline-color: #fff;
$tabs-text-color: #fff;
$tabs-bg-color: $primary-color;

$h1-fontsize: 2.7rem;
$h2-fontsize: 2.56rem;
$h3-fontsize: 2.17rem;
$h4-fontsize: 1.655rem;
$h5-fontsize: 1.0775rem;
$h6-fontsize: 1rem;

$sidenav-width: 240px;

@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #F7F7F7; }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #BFBFBF; } }
