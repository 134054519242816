.app-loader {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.loader {
  display: flex; }
main > .container .loader {
  height: calc(100vh - 20px - #{$navbar-height} - 64px - 48px);
  align-items: center; }
main > .ember-view .loader {
  margin-top: calc(100% / 2); }
.preloader-wrapper {
  margin: 0 auto; }
.loader-card {
  margin-top: 32px;
  margin-bottom: 32px; }

@media #{$small-and-down} {
  main > .container .loader {
    height: calc(100vh - 28px - #{$navbar-height-mobile} - 64px - 48px); } }
