.users-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(255,255,255,0.7);
  display: flex;
  flex-direction: column;
  justify-content: center; }
