.db-info-logo {
  float: left;
  height: 36px;
  margin-bottom: -8px;
  margin-right: 8px;
  margin-top: -8px;
  max-height: 36px; }

.db-info-url {
  word-break: break-all; }

.db-cluster-health {
  td:first-child {
    width: 50%; }
  i {
    vertical-align: middle; } }

.db-stats {
  td:first-child {
    width: 50%; } }

.db-stats-usage {
  ul {
    margin: 0; } }
.db-stats-usage-title {
  font-weight: initial; }
.db-stats-usage-value {
  display: block;
  max-width: 115px;
  text-align: right; }

.db-stats-usage-item {
  padding-left: 16px;
  span:first-child {
    display: inline-block;
    width: 50%; }
  span:last-child {
    width: 115px;
    display: inline-block;
    text-align: right; }
  .current {
    @extend .blue-text; }
  .max {
    @extend .blue-text.text-lighten-1; }
  .limit {
    @extend .green-text.text-lighten-2; } }

.db-stats-chart {
  position: relative;
  display: flex;
  margin-top: 16px;
  margin-bottom: 28px; }
.db-stats-chart-bar, .db-stats-chart-indicator {
  height: 16px;
  line-height: 16px;
  color: white;
  text-align: center;
  &.current {
    @extend .blue.lighten-1; }
  &.max {
    @extend .blue.lighten-1; }
  &.limit {
    @extend .green.lighten-3; }
  small {
    vertical-align: middle; } }
.db-stats-chart-indicator {
  position: absolute;
  width: 2px;
  height: 20px;
  margin-top: -2px;
  &:before {
    content: '';
    position: absolute;
    top: -2px;
    left: -3px;
    border-style: dashed dashed solid;
    border-color: transparent transparent color('blue', 'lighten-1');
    border-width: 0px 4px 4px;
    transform: rotate(180deg); }
  &:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: -3px;
    border-style: dashed dashed solid;
    border-color: transparent transparent color('blue', 'lighten-1');
    border-width: 0px 4px 4px; }
  small {
    @extend .blue-text.text-lighten-1;
    top: 20px;
    position: relative;
    left: -18px; } }

.container {
  .card-content {
    form {
      em {
        margin-left: 1em; } }
    p {
      margin-top: 13px;
      margin-bottom: 13px; }
    .command-line {
      background-color: black;
      color: white;
      padding-left: 5px; } } }

.upgrade-message {
  white-space: pre-line; }
