.fullscreen {
  padding-top: 128px;
  padding-bottom: 128px;
  min-height: 100vh;
  @media #{$small-and-down} {
    padding-top: 16px;
    padding-bottom: 16px; }
  > .row {
    margin: 0; } }

.sso {
  .title {
    color: $primary-color;
    font-weight: 300;
    margin: 30px 0;
    img {
      height: 40px; } }
  button[type=submit] {
    width: 100%;
    margin: 24px 0; }
  .links {
    p {
      margin-bottom: 0;
      &.text-muted {
        a {
          margin-left: 2px; } } }
    img {
      margin-top: 15px;
      height: 40px; } }
  .error {
    @extend .red-text;
    a {
      @extend .red-text;
      text-decoration: underline; } } }
