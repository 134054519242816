nav {
  z-index: 2;
  position: relative;
  height: auto;
  .nav-wrapper {
    display: flex;
    padding: 0 15px;
    position: static; }
  .brand-logo {
    position: inherit;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    flex: 1;
    margin-left: 15px; }
  .dropdown-content {
    li {
      > a, > span {
        color: #444;
        display: block;
        padding: 1rem 1rem;
        font-size: 1em; } }
    i {
      font-size: 1.5rem; } }
  .materialize-tabs {
    margin: 0;
    a {
      cursor: pointer; } } }

.mongo-color {
  background-color: #412F1F;
  .tabs {
    background-color: #412F1F; } }
.mysql-color {
  background-color: #00618A;
  .tabs {
    background-color: #00618A; } }
.postgresql-color {
  background-color: #336791;
  .tabs {
    background-color: #336791; } }
.redis-color {
  background-color: #A41E11;
  .tabs {
    background-color: #A41E11; } }
.elasticsearch-color {
  background-color: #3CBEB1;
  .tabs {
    background-color: #3CBEB1; } }
.influx-color {
  background-color: #3679BE;
  .tabs {
    background-color: #3679BE; } }


.navbar-menu {
  cursor: pointer;
  display: inline-flex;
  position: relative;
  > i {
    width: 64px; }
  &:active {
    > i {
      -webkit-filter:brightness(90%) {}
      filter:brightness(90%) {} } }
  &:active::after {
    position: absolute;
    margin: auto;
    padding: 4px;
    background-color: rgba(0,0,0,.1);
    border-radius: 50%;
    content: ' ';
    top: 7px;
    left: 7px;
    right: 7px;
    bottom: 7px;
    z-index: -1; } }

// Hack for Firefox to handle :active correctly on .navbar-menu
.navbar-menu-placeholder {
  position: absolute;
  left: 0px;
  width: 100%;
  height: 100%; }

@media #{$medium-and-down} {
  nav {
    padding-left: 0; } }

@media #{$small-and-down} {
  nav {
    position: fixed; }

  .navbar-menu {
    > i {
      width: $navbar-height-mobile; } }

  .navbar-account-menu img {
    height: $navbar-height-mobile;
    padding: ($navbar-height-mobile - ($navbar-height-mobile - 8.75px)); } }
